<template>
    <div class="expertdetails">
        <div class="banner">
            <img src="../assets/images/expert/banner.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/">专家介绍</a>
            </div>
        </div>
        <div class="expert">
            <div class="expert-content">
               <div class="content-left">
                    <img :src="zjjs.img"  alt="" srcset="">
                </div>
                <div class="content-right">
                    <p class="name">{{zjjs.name}}</p>
                    <p class="position">{{zjjs.position}}</p>
                    <p class="line-style">——</p>
                    <p class="description">{{zjjs.description}}</p>
<!--                    <p class="description"> 某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总 </p> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
	import request from "../utils/request.js";
		  import { apiUrl ,fileUrl} from '@/config'
	import Swiper from "swiper";
	import { setTimeout } from "timers";
export default {
    data(){
        return {
			fileUrl:fileUrl,
			zjjs:{
				img:'',
				position:'',
				name:'',
				description:''
			},
			//imgurl:"https://www.whmise.com/mise",
			queryId:this.$route.params.queryId,
			dataId:this.$route.params.dataId, 
		}
    },
	methods: {
	 /**专家介绍详情**/
	 getzjjsxq(){
	 	var that=this
	 	var queryId=that.queryId
	 	var dataId=that.dataId
	 	request.get('/formMake/view/'+queryId+'/'+dataId).then(res =>{
	 		if(res.code==0){
	 			const {data}=res.data
	 			that.zjjs.name=data.editMData.xm
	 		    that.zjjs.position=data.editMData.zw
	 			that.zjjs.description=data.editMData.jj
	 			if (data && data.editMData && data.editMData.tx && data.editMData.tx.length > 0) {  
	 			    // 获取第一个图片的URL  
	 			    var imageUrl = data.editMData.tx[0].url;  
	 			      
	 			    // 将URL赋值给that.zzfg.img  
	 			    that.zjjs.img = that.fileUrl+imageUrl;  
					 console.log( that.zjjs.img)
	 			} else {  
	 			    console.error("No image URL found in the data.");  
	 			}
	 			
	 		}
	 	})
	 
	 
	 }
	  
	},
    mounted(){
		this.getzjjsxq()
	}
}
</script>
<style lang="scss" >
.expertdetails{
    box-sizing: border-box;
    .banner{
        width: 100%;
    }
    .orientation{
        background-color: #f5f5f5;
        height: 40px;   
        margin-bottom: 30px;
        .orientation-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 45px;
            color:#666666
        }
    }
    .expert{
        margin: 80px 0;
        .expert-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .content-left{
                width: 30%;
            }
            .content-right{
                width: 75%;
                .name{
                    font-size: 18px;
                    font-weight: bold;
                    color: #333333;
                }
                .position{
                    font-size: 14px;
                    font-weight: 500;
                }
                .line-style{
                    color: #0857d0;
                    font-weight: 900;
                }
                .description{
                    font-size: 14px;
                    color: #666666;
                    text-indent: 2em;
                    font-weight: 500;
                    margin: 15px 0;
                }
            }
        }
    }
}
</style>
